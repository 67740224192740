import React, { useState, useEffect } from "react";

const MenuWrap = ({ children, side, wait }) => {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    setHidden(true);
    setTimeout(() => {
      show();
    }, wait);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const show = () => {
    setHidden(false);
  };

  return (
    <div style={hidden ? { display: "none" } : null} className={side}>
      {children}
    </div>
  );
};

export default MenuWrap;
