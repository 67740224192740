import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { AppContext } from "./../AppContext";

export const checkData = (Component) => (props) => {
  let history = useHistory();
  const { language, collections } = useContext(AppContext);
  useEffect(() => {
    if (!language || !collections.length) {
      history.push("/languages");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Component {...props} />;
};
