import styled from "styled-components";
import { Button } from "react-bootstrap";

export const AppButton = styled(Button)`
  color: #2670ab;
  background-color: transparent;
  font-family: Armata-Regular;
  letter-spacing: 1px;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 25px;
  border: 1px solid #2670ab;
  &:hover {
    outline: none;
    box-shadow: 0 0 3pt 2pt #2670ab;
    color: #ffffff;
    background-color: #2670ab;
    border-color: #2670ab !important;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #2670ab;
    color: #ffffff;
    background-color: #2670ab;
    border-color: #2670ab !important;
  }
`;
