import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { getFile } from "./../../services";

const Player = ({ url, state }) => {
  return (
    <audio src={url} controls controlsList="nodownload">
      Error: your web browser does not support this audio player.
    </audio>
    // <ReactPlayer className="react-player-audio" url={url} playing={state} />
  );
};

const Audio = ({ audioId, name }) => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(false);

  useEffect(() => {
    fetchAudio();
    return () => {
      setState(false);
      setUrl(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioId]);

  const fetchAudio = async () => {
    try {
      setLoading(true);
      if (audioId) {
        const { data } = await getFile(audioId);
        const url = URL.createObjectURL(data);
        setUrl(url);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading && <Spinner style={{ marginTop: 10 }} animation="grow" />}
      {!loading && url && <Player url={url} state={state} />}
    </React.Fragment>
  );
};

export default React.memo(Audio, (prevProps, props) => {
  return prevProps.audioId === props.audioId;
});
