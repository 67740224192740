import _ from "lodash";
export const sortsLanguages = (array) => {
  // for (let i = 0; i < array.length - 1; i++) {
  //   for (let j = i + 1; j < array.length; j++) {
  //     if (parseInt(array[i].orden) > parseInt(array[j].orden)) {
  //       let aux = array[i];
  //       array[i] = array[j];
  //       array[j] = aux;
  //     }
  //   }
  // }

  array = array.map((item) => {
    item.orden = parseInt(item.orden);
    return item;
  });

  array = _.sortBy(array, ["orden"]);

  return array;
};
