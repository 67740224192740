import React from "react";

const Player = ({ url, state }) => {
  return (
    <audio src={url} controls controlsList="nodownload">
      Error: your web browser does not support this audio player.
    </audio>
    // <ReactPlayer className="react-player-audio" url={url} playing={state} />
  );
};

const Audio = ({ url }) => {
  return (
    <React.Fragment>
      <Player url={url} />
    </React.Fragment>
  );
};

export default React.memo(Audio, (prevProps, props) => {
  return prevProps.url === props.url;
});
